@import "../../abstracts/functions";

$body-font-color: get-color(medium-grey);

$link-color: get-color(dark-blue-1);
$link-hover-color: get-color(dark-blue-2);

html {
  font-size: 16px;
}

body {
  color: $body-font-color;
  font-family: Arial, Helvetica, sans-serif;
  // font-size: 62.5%; // 1em = 10px
}

a {
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: $link-hover-color;
}

canvas {
  display: block;
}

// scss-lint:disable ImportantRule, SingleLinePerSelector, SelectorFormat
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
// scss-lint:enable ImportantRule, SingleLinePerSelector, SelectorFormat
