.form-wrapper {
  padding-left: 40px;
  .form-group {
    margin-right: 0;
    margin-left: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.btn.btn-back {
  margin-right: 25px;
}
.btn[disabled] {
  opacity: .2;
}
.btn.btn-next {
  background-color: #0066CC;
  color: #fff;
}
.btn.btn-raised.btn-next:hover {
  background-color: #369aff;
}

.clear {
  clear: both;
  display: block;
}
