a,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
