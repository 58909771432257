$button-success-background-color: get-color(green);
$button-success-color: get-color(white);

$button-success-dark-background-color: get-color(green-dark);
$button-success-dark-color: get-color(white);

button[color="success"] {
  background-color: $button-success-background-color;
  color: $button-success-color;
}

button[color="success-dark"] {
  background-color: $button-success-dark-background-color;
  color: $button-success-dark-color;
}
