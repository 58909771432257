@import "./abstracts/variables";
@import "./abstracts/functions";

@import "./themes/mvp-theme";
// Typography has to be imported after themes, because it overides default mat-typography
@import "./base/typography";
@import "./base/reset";

@import "./components/buttons";
@import "./components/mat-checkbox";
@import "./components/application-steps";

@import '~mdb-ui-kit/css/mdb.min.css';

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

@import "~@ng-select/ng-select/themes/material.theme.css";

$app-background-color: get-color(light-grey-1);

html,
body {
  margin: 0;
  height: 100%;
  background-color: $app-background-color;
}

// TO DO: Make sure that this is necessary
@font-face {
  font-family: "generica";
  src: url("/assets/fonts/generica.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

input.mat-input-element {
  margin-bottom: 6px;
}

.user-tooltip {
  white-space: pre-line;
}
